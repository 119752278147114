<template>
  <!-- Start Blog Area -->
  <div class="rn-blog-area home-one-blog d-flex bg_color--3" id="blog">
    <div class="rn-blog-left w-50">
      <div class="blog-content d-flex align-items-center h-100">
        <div class="text-left content news-content rn-plr section-ptb-xl">
          <h2 class="section-title">Naujienos</h2>
          <div class="blog-wrapper">
            <!-- Pradzia naujienos bloko -->
            <div class="rn-blog">
              <h2>
               Spektaklis -
                  <span class="date">2017-01-30 </span>
              </h2>
              <p>
                  Saugų bendruomenė ruošiasi naujo spektaklio pastatymui, 
                  režisierius Mindaugas Nogaitis, kviečiame
                  visu norinčius įsijungti į vaidybą. 
              </p>
              <p>
                Š.m. metų Joninėms Saugų bendruomenė stato spektaklį "Joninės", 
                režisierius Mindaugas Nogaitis, kviečiame visu norinčius įsijungti į 
                vaidybą. Nepasididžiuokite, rolių užteks visiems.
              </p>
            </div>
            <!-- Pabaiga naujienos blogo -->
            <!-- Pradzia naujienos bloko -->
            <div class="rn-blog">
              <h2>Sporto salė -
                  <span class="date">2017-01-30 </span>
              </h2>
              <p>
                 Informuojame, kad Saugų Jurgio Mikšo mokykla, geranoriškai 
                 leido Saugų bendruomenei naudotis mokyklos sporto sale
                  neatlygintinai.

                Kviečiame visus antradieniais nuo 18 val. pažaisti tinklinį.
              </p>
            </div>
            <!-- Pabaiga naujienos blogo -->
          </div>
        </div>
      </div>
    </div>

    <div class="rn-blog-right w-50 ">
      <v-fade-transition leave-absolute>
        <div
          v-if="activetab === 1"
          class="h-full rn-images-bg-wrapper"
          :style="{ backgroundImage: 'url(' + src1 + ')' }"
        ></div
      ></v-fade-transition>
    </div>
  </div>
  <!-- End Blog Area -->
</template>

<script>
  export default {
    data() {
      return {
        el: "#blog",
        activetab: 1,
        src1: require("../../assets/img/saugos/malunas.jpg"),
      };
    },
  };
</script>

<style lang="scss" scoped>
  .rn-blog-area {
    overflow: hidden;
  }
</style>
