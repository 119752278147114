<template>
    <FooterTwo />
  <!--// Footer -->
</template>

<script>
  import FooterTwo from "./FooterTwo";
  export default {
    components: {
      FooterTwo,
    },
    data() {
      return {
        quickLink:[
        ],
      };
    },
  };
</script>

<style lang="scss" scoped></style>
