<template>
  <div class="slider-wrapper default-home-slider">
    <VueSlickCarousel v-bind="settings" class="slider-activation-with-slick">
      <div
        class="slide slider-container"
        v-for="(slider, i) in sliderContent"
        :key="i"
      >
        <div class="slider-inner">
          <div class="slide-content txt">
            <div class="txt-wrapper">
              <span class="copy">{{ slider.info }}</span>
              <h2 class="copy2">{{ slider.title }}</h2>
              <p class="excerpt copy3">{{ slider.desc }}</p>
              <ul class="social-icon hover-icon icon-position">
                <li>
                  <a
                    v-for="(icon, i) in slider.socialIconList"
                    :key="i"
                    :data-hover="icon.dataHover"
                    :href="icon.url"
                    target="_blank"
                    >{{ icon.name }} <span class="circle"></span
                  ></a>
                </li>
              </ul>
            </div>
          </div>

          <div class="slide-content img">
            <img :src="slider.src" alt="personal portfolio" />
          </div>
        </div>
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
  import VueSlickCarousel from "vue-slick-carousel";
  // import Social from "../social/Social";
  export default {
    components: { VueSlickCarousel },
    data() {
      return {
        sliderContent: [
          {
            src: require("../../assets/img/saugos/landing_old.jpg"),
            info: "Saugų kaimo bendruomenė",
            title: "Sveiki!",
            desc: `Saugų kaimo bendruomenė  įkurta 1996 metų lapkričio 14 dieną.`,
            socialIconList: [
              {
                name: "FB",
                dataHover: "FB",
                url: "https://www.facebook.com/groups/1015437091992388",
              },
            ],
          }
        ],
        settings: {
          fade: true,
          dots: true,
          arrows: false,
          infinite: true,
          speed: 1000,
          slidesToShow: 1,
          slidesToScroll: 1,
          margin: 20,
        },
      };
    },
  };
</script>

<style lang="scss" scoped></style>
