<template>
  <div>
   <HeaderFive>
   </HeaderFive>
    <SliderThree />

    <News />

    <Footer />
  </div>
</template>

<script>
  import HeaderFive from "../../components/header/HeaderFive";
  import SliderThree from "../../components/slider/SliderThree";
  //import PortfolioTwo from "../../components/portfolio/PortfolioTwo";
 // import Awards from "../../components/awards/Awards";
  import Footer from "../../components/footer/Footer";
  import News from '../../components/news/News.vue';
  export default {
    components: {
      HeaderFive,
      SliderThree,
      //PortfolioTwo,
     // Awards,
      News,
      Footer
    },
    data() {
      return {};
    },
  };
</script>

<style lang="scss" scoped></style>
